<template>
  <main>


    <div class="container">

      <div class="contentUsTtile ">
        <a :href="'mailto:service@coinearn.org?subject=' + accountAddress"   target="_blank" class="contentUs">📧{{ $t('postPage.email') }}</a>
        <a href="https://t.me/coinearn_org" target="_blank" class="contentUs">✈️{{ $t('postPage.tg') }}</a>
      </div>
      <div v-if="data.length>0" class="account-list">
        <div  v-for="(item, index) in data"  :key = "index"  class="account-item"  @click=ToView(item)>
          <div class="account-id">{{item.created}}</div>
          <div class="account-status">{{item.title}}</div>
        </div>
      </div>
    </div>
  </main>

  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>

</template>

<script>
import axios from "axios";
import config from "@/config";
import {mapState} from "vuex";
export default {
  name: 'PostyPage',
  data() {
    return {
      loading:false,
      data:[],
    }
  },
  mounted(){
  },
  created() {
    this.fetchHistory()
  },
  watch: {
  },
  computed: {
    ...mapState({
      walletConnected: state => state.walletConnected,
      walletLogin: state => state.walletLogin,
      accountAddress: state => state.accountAddress,
      walletConnectModel: state => state.walletConnectModel,
      team_arp: state => state.team_arp,
      balance: state => state.balance,
      level: state => state.level,
      account_info: state => state.account_info,
    }),
  },
  methods: {
    ToView(item){
      this.$router.push({ name: "postdetail", params: { date: item.created,text: item.text,title:item.title} });
    },
    async fetchHistory(){
      this.loading = true
      axios.get(config.apiHost+'/post_list', {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        // 处理响应数据
        this.data = response.data
        this.loading = false
      }).catch(error => {
        this.loading = false
        // 处理错误
        console.error(error);
      });
    }
  }
};
</script>

<style scoped>

.account-list {
  margin-top: 1rem;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.account-item {
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #444;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.contentUsTtile{
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}


.account-item:last-child {
  border-bottom: none;
}

.account-id {
  font-size: 1rem;
  width: 150px;
}

.account-status {
  font-size: 1rem;
  color: #bbb;
  width: 150px;
}
.link-section {
  text-align: center;
}
.contentUs{
  font-size: 1rem;
  text-align: center;
  background-color: #333;
  border-radius: 10px;
  padding: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: white;
  text-decoration: none;
}
.link-section a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
}
</style>
