<template>

  <div v-if="!walletLogin" class="container">
    <div class="logo-container">
      <p style="font-size: 5rem;">🌷</p>
    </div>
    <w3m-button v-if="!walletConnected" :label="this.$t('walletConnected')" :loadingLabel="this.$t('openWallet')"/>
    <button v-if="walletConnected && !walletLogin" class="login-button" @click="toWalletLogin">{{$t('login')}}</button>
  </div>
  <main v-if="walletLogin">
    <div  style="text-align: center" v-if="data.length == 0"> <span>{{ $t('orderPage.noOrders') }}</span></div>
    <div class="investment-list" v-if="data.length>0">
      <div  v-for="(item, index) in data" :key="index" @click=ToViewOrder(item) class="investment-item">
        <div class="investment-title">{{item.product_name}}</div>
        <div class="investment-detail">
          <div class="info">{{$t('orderPage.arp')}} <span>{{item.arp}}%</span></div>
          <div class="info">{{$t('orderPage.end_time')}} <span>{{item.end_at_date}}</span><span>{{item.end_at_time}}</span></div>
          <div class="info">{{orderStatus[item.status]}}</div>
        </div>
      </div>
    </div>
  </main>

  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>

</template>

<script>

import axios from 'axios';
import {mapState} from "vuex";
import config from "@/config";
import {getCookie} from "@/utils/cookie";

export default {
  name: 'OrderPage',
  data() {
    return {
      loading: false,
      data: [],
      orderStatus:{
        0:this.$t('orderPage.lockedPos'),
        1:this.$t('orderPage.waitRed'),
        2:this.$t('orderPage.redComple'),
        3:this.$t('orderPage.unexpected')
      }
    };
  },
  watch: {
    walletLogin(newValue, oldValue) {
      if(newValue){
        this.fetchData();
      }
      console.log('someData walletLogin changed from', oldValue, 'to', newValue);
    }
  },
  created() {
  },
  mounted() {
    if(this.walletLogin){
      this.fetchData();
    }
  },
  computed: {
    ...mapState({
      walletConnected: state => state.walletConnected,
      walletLogin: state => state.walletLogin,
      accountAddress: state => state.accountAddress,
      walletConnectModel: state => state.walletConnectModel,
      team_arp: state => state.team_arp,
      balance: state => state.balance,
      level: state => state.level,
      account_info: state => state.account_info,
    }),
  },
  methods: {
    ToViewOrder(item){
      this.$router.push({ name: "OrderDetail", params: {
        id: item.id,
          product_name: item.product_name,
          arp: item.arp,
          create_at: item.create_at,
          end_at: item.end_at,
          et_arp: item.everday_team_arp,
          status: item.status,
          amount:item.amount,
          } });
    },
    async toWalletLogin(){
      try {
        this.$store.commit('setShowWalletMsg',this.$t('go_wallet_to_do'));
        await this.$store.dispatch('walletLogin');
        this.$store.commit('setShowWalletMsg',"");
      } catch (error) {
        console.error('Failed to disconnect:', error);
      }
    },
    async fetchData() {
      this.loading = true
      axios.get(config.apiHost+'/order_list', {
        headers: {
          'Content-Type': 'application/json',
          'loginsign':getCookie("signature"),
          'Loginmsg':getCookie("login_msg"),
        }
      }).then(response => {
        // 处理响应数据
        var data = response.data
        if(data.login){
          this.data = data.data;
        }else{
          this.$store.commit('setLoginOut')
          this.$router.push({ name: "MyPage", params: {} });
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        // 处理错误
        console.error(error);
      });
    },
  },
};

</script>

<style scoped>

.investment-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 5rem;
  overflow: hidden;
}

.investment-item {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  text-decoration: none;
  color: white;
}

.investment-title {
  font-size: 1rem;
  margin-bottom: 10px;
}

.investment-detail {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.investment-detail .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.investment-detail .info span {
  margin-top: 5px;
  font-size: 1rem;
  color: #bbb;
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.logo {
  width: 200px;
  height: 200px;
}

.login-button {
  padding: 10px 40px;
  background-color: #ff00ff;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
}

.login-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}


.login-button:hover {
  background-color: #e600e6;
}

</style>
