// src/i18n.js
import { createI18n } from 'vue-i18n';


// 获取 Cookie 的函数
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

// 根据 lang_index 动态加载语言文件
const langIndex = getCookie('lang_index') || 'zh'; // 默认英文
let messages = {};
if (langIndex === 'zh') {
    messages = { zh: require('./lang/zh').default };
} else {
    messages = { en: require('./lang/en').default };
}
const i18n = createI18n({
    locale: langIndex,
    fallbackLocale: 'en',
    messages
});

export default i18n;