<template>

  <main>
    <div class="product-details">
      <div class="product-title">{{ name }}</div>
      <div class="product-info">
        <div class="info-item">
          {{ $t('productDetail.arp') }} <span>{{arp}}%</span>
        </div>
        <div class="info-item">
          {{ $t('productDetail.minimum') }} <span>{{start_funds}} USDT</span>
        </div>
        <div class="info-item">
          {{ $t('productDetail.holdingPeriod') }} <span>{{cycle}} {{ $t('productDetail.day') }}</span>
        </div>
      </div>
      <div class="product-description">
        &nbsp;&nbsp;&nbsp;&nbsp;{{describe}}
      </div>
      <div class="interest-details">
        <div class="interest-item"> {{ $t('productDetail.basic_interest') }} <span>{{arp}}%</span></div>
        <div class="interest-item"> {{ $t('productDetail.team_interest') }}  <span>{{team_arp}}%</span></div>
        <div class="interest-item">{{ $t('productDetail.total_interest') }}  <span>{{parseFloat(arp)+parseFloat(team_arp)}}%</span></div>
        <div class="interest-item">{{ $t('productDetail.expected_returns') }} <span>{{(cycle * (buy_funds*((parseFloat(arp)+parseFloat(team_arp))/100)/365)).toFixed(2)}} USDT</span></div>
      </div>
      <div class="investment">
        {{ $t('productDetail.principal') }} <input type="number" v-model="buy_funds">
      </div>
      <button class="buy-button"   @click="Purchase"> {{ $t('button.buy') }}</button>
    </div>


    <div v-if="showDialog" class="dialogdiv">
      <div id="overlay" class="overlay"></div>
      <div id="dialog" class="dialog">
        <div class="dialog-header">
          <span>{{ $t('productDetail.purchase_confirmation') }}</span>
          <button class="close-button" @click="closeDialog()">&times;</button>
        </div>
        <div class="dialog-content">
          <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('productDetail.condescribe1') }} {{name}} {{ $t('productDetail.condescribe2') }},{{ $t('productDetail.condescribe3') }} {{cycle}} {{ $t('productDetail.condescribe4') }},{{ $t('productDetail.condescribe5') }} {{parseFloat(arp)+parseFloat(team_arp)}}%,{{ $t('productDetail.condescribe6') }} {{(cycle * (buy_funds*((parseFloat(arp)+parseFloat(team_arp))/100)/365)).toFixed(2)}} USDT {{ $t('productDetail.condescribe7') }}?</p>
        </div>
        <div class="dialog-actions">
          <button class="cancel-button" @click="closeDialog()">{{ $t('button.cancel') }}</button>
          <button class="confirm-button" @click="confirmPurchase()">{{ $t('button.confirmation') }}</button>
        </div>
      </div>
    </div>


    <div v-if="showerror" class="dialogdiv">
      <div id="overlay" class="overlay"></div>
      <div id="dialog" class="dialog">
        <div class="dialog-header">
          <span>❗</span>
          <button class="close-button" @click="closeError()">&times;</button>
        </div>
        <div class="dialog-content">
          <p>{{errorMsg}}</p>
        </div>
        <div class="dialog-actions">
          <button class="cancel-button" @click="closeError()">{{ $t('button.cancel') }}</button>
          <button class="confirm-button" @click="toCharge()">{{ $t('button.charge') }}</button>
        </div>
      </div>
    </div>

  </main>

  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import axios from "axios";
import config from "@/config";
import {Web3} from "web3";
import {getCookie} from "@/utils/cookie";

export default {
  name: 'OrderDetail',
  data() {
    return {
      showerror:false,
      showDialog: false,
      id: this.$route.params.id,
      name: this.$route.params.name,
      arp: parseFloat(this.$route.params.arp),
      start_funds: this.$route.params.start_funds,
      cycle: this.$route.params.cycle,
      describe: this.$route.params.describe,
      team_arp: this.$route.params.team_arp,
      buy_funds: this.$route.params.start_funds,
      loading:false,
      errorMsg:''
    };
  },
  computed: {
    ...mapState({
      showWalletDialog: state => state.showWalletDialog,
      walletConnected: state => state.walletConnected,
      walletLogin: state => state.walletLogin,
      accountAddress: state => state.accountAddress,
      walletConnectModel: state => state.walletConnectModel,
      team_arp: state => state.team_arp,
      balance: state => state.balance,
      level: state => state.level,
      account_info: state => state.account_info,
    }),
  },
  created() {
  },
  methods: {
    toCharge(){
      this.$router.push({ name: "DepositPage", params: {} });
    },
    openDialog(){
      this.showDialog = true;
    },
    closeError(){
      this.showerror = false
      this.showDialog = false
    },
    openError(msg){
      this.errorMsg = msg
      this.showerror = true
    },
    closeDialog() {
      this.showDialog = false;
    },
    Purchase() {
      if(!this.walletLogin){
        this.$router.push({ name: "MyPage", params: {} });
      }else{
        this.openDialog();
      }
    },
    async tobuytypesign(serverD){
      try {
        const provider = await this.walletConnectModel.getWalletProvider();
        const web3 = new Web3(provider);
        this.$store.commit('setShowWalletMsg',this.$t('go_wallet_to_do'));
        const signature = await web3.eth.signTypedData(this.walletConnectModel.getAddress(), serverD['extra_data']);
        const yewu_data = {
            buysign: signature,
            p_id: this.id,
            amount:this.buy_funds
        }
        this.loading = true
        var data = {
          "method":"buy",
          "type":"typesign",
          "type_id":serverD['type_id'],
          "data":yewu_data,
          "extra_data":serverD['extra_data'],
          "signature":signature,
          "sign_msg":serverD['extra_data'],
        }
        if (this.walletConnectModel.getChainId() == 56){
          data["network"] = "bsc"
        }
        await axios.post(config.apiHost+'/sign_method', data,{
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        }).then(response => {
          this.loading = false
          var orderdata = response.data
          if(orderdata.login){
            // 处理响应数据
            if(orderdata.ok){
              var item = orderdata["order"]
              this.$store.commit('setBalance', this.balance-item.amount);
              this.$router.push({ name: "OrderDetail", params: {
                  id: item.id,
                  product_name: item.product_name,
                  arp: item.arp,
                  create_at: item.create_at,
                  end_at: item.end_at,
                  et_arp: item.everday_team_arp,
                  status: item.status,
                  amount:item.amount,
                } });
              this.$store.commit('setShowWalletMsg',"");
            }else{
              this.openError(orderdata.msg)
            }
          }else{
            this.$store.commit('setLoginOut')
            this.$router.push({ name: "MyPage", params: {} });
          }

        }).catch(error => {
          console.error(error);
        });
      } catch (error) {
        this.loading = false
        this.showerror = false
        this.showDialog = false
        console.log(error)
      } finally {
        console.error(2);
      }
      this.loading = false
    },
    async tobuysign(serverD){
      try {
        const provider = await this.walletConnectModel.getWalletProvider();
        const web3 = new Web3(provider);
        const hexMessage = web3.utils.utf8ToHex(serverD["sign_msg"]);
        this.$store.commit('setShowWalletMsg',this.$t('go_wallet_to_do'));
        const signature = await web3.eth.personal.sign(hexMessage, this.walletConnectModel.getAddress(), "");
        const yewu_data = {
          buysign: signature,
          p_id: this.id,
          amount:this.buy_funds
        }
        this.loading = true
        var data = {
          "method":"buy",
          "type":"sign",
          "data":yewu_data,
          "extra_data":serverD['extra_data'],
          "signature":signature,
          "sign_msg":serverD['sign_msg'],
        }
        if (this.walletConnectModel.getChainId() == 56){
          data["network"] = "bsc"
        }
        await axios.post(config.apiHost+'/sign_method',data ,{
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        }).then(response => {
          var orderdata = response.data
          if(orderdata.login){
            // 处理响应数据
            if(orderdata.ok){
              var item = orderdata["order"]
              this.$store.commit('setBalance', this.balance-item.amount);
              this.$router.push({ name: "OrderDetail", params: {
                  id: item.id,
                  product_name: item.product_name,
                  arp: item.arp,
                  create_at: item.create_at,
                  end_at: item.end_at,
                  et_arp: item.everday_team_arp,
                  status: item.status,
                  amount:item.amount,
                } });
              this.$store.commit('setShowWalletMsg',"");
            }else{
              this.openError(orderdata.msg)
            }
          }else{
            this.$store.commit('setLoginOut')
            this.$router.push({ name: "MyPage", params: {} });
          }
        }).catch(error => {
          console.error(error);
        });
      } catch (error) {
        this.loading = false
        this.showerror = false
        this.showDialog = false
        this.$store.commit('setShowWalletMsg',"");
        console.log(error)
      } finally {
        console.error(2);
      }
      this.loading = false
    },
    async handleResponse(response) {
      var serverD = response.data
      if(!serverD.login){
        this.$store.commit('setLoginOut')
        this.$router.push({ name: "MyPage", params: {} });
        return
      }
      if(serverD["type"] == "sign"){
        await this.tobuysign(serverD)
      }
      if(serverD["type"] == "typesign"){
        await this.tobuytypesign(serverD)
      }
    },
    async confirmPurchase(){
      //this.loading=true
      if(this.buy_funds<this.start_funds){
        this.$t('go_wallet_to_do')
        this.$store.commit('setShowWalletMsg',this.$t('investment_is_too_small'));
        return
      }
      if(this.balance>this.buy_funds){
        var data = {
          "method":"buy",
          "data":{
            "productid":this.id
          }
        }
        if (this.walletConnectModel.getChainId() == 56){
          data["network"] = "bsc"
        }
        const response = await axios.post(config.apiHost+'/getsign', data, {
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        })
        await this.handleResponse(response)
      }else{
        this.openError(this.$t('no_balance'))
      }

    }
  },
};
</script>

<style scoped>
.dialogdiv{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.product-details {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.product-title {
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center;
}

.product-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-item span {
  margin-top: 5px;
  font-size: 1rem;
  color: #bbb;
}

.product-description {
  margin-bottom: 20px;
}

.interest-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.interest-item {
  display: flex;
  justify-content: space-between;
}

.investment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4d4d4d;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.investment input {
  width: 80px;
  border: none;
  background-color: transparent;
  color: white;
  text-align: right;
  font-size: 1rem;
}

.buy-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ff00ff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
}

.buy-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}


.dialog {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 2000;

}
.dialog-actions {
  display: flex;
  justify-content: space-between;
}

.cancel-button, .confirm-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
}

.cancel-button {
  background-color: #555;
  color: white;
}

.confirm-button {
  background-color: #ff00ff;
  color: white;
}
.confirm-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}


</style>
