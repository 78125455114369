<template>
  <div v-if="!walletConnected" class="container">
    <div class="logo-container">
      <p style="font-size: 5rem;">🌷</p>
    </div>
    <w3m-button v-if="!walletConnected" :label="$t('walletConnected')" :loadingLabel="$t('openWallet')"/>
    <button v-if="walletConnected && !walletLogin" class="login-button" @click="toWalletLogin">{{$t('login')}}</button>
  </div>


  <main v-if="walletConnected">
    <div class="account-info">
      <div class="account-id"><w3m-button :label="$t('walletConnected')" :loadingLabel="$t('openWallet')" size="sm"/></div>
      <div class="info-grid">
        <div class="info-item">
          <div class="label">{{ $t('my.availableFunds') }}</div>
          <div class="value" v-if="!walletLogin">0 USDT</div>
          <div class="value" v-if="walletLogin">{{balance.toFixed(2)}} USDT</div>
        </div>
        <div class="info-item">
          <div class="label">{{ $t('my.team_interest') }}</div>
          <div class="value"  v-if="!walletLogin">0</div>
          <div class="value"  v-if="walletLogin"><span class="team_info">ARP:{{team_arp}}%</span></div>
        </div>
      </div>
    </div>
    <div class="action-list">
      <router-link v-if="walletLogin" to="/withdrawals" class="action-item">
        <div class="icon">↩️</div>
        <div class="label">{{ $t('my.withdrawals') }}</div>
        <div class="chevron">></div>
      </router-link>
      <router-link v-if="walletLogin" to="/deposit" class="action-item">
        <div class="icon">🔄</div>
        <div class="label">{{ $t('my.charge') }}</div>
        <div class="chevron">></div>
      </router-link>
      <router-link v-if="walletLogin" to="/history" class="action-item">
        <div class="icon">📡</div>
        <div class="label">{{ $t('my.bill') }}</div>
        <div class="chevron">></div>
      </router-link>
      <router-link v-if="walletLogin" to="/team" class="action-item">
        <div class="icon">👥</div>
        <div class="label">{{ $t('my.team') }}</div>
        <div class="chevron">></div>
      </router-link>
      <router-link v-if="walletLogin" to="/post" class="action-item">
        <div class="icon">📢</div>
        <div class="label">{{ $t('my.announcement') }}</div>
        <div class="chevron">></div>
      </router-link>
      <button class="tologin-button" v-if="!walletLogin" @click="toWalletLogin">{{ $t('login') }}</button>
    </div>
  </main>
  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>


</template>

<script>
import axios from "axios";
import config from '../config';
import { mapState } from 'vuex';
import {getCookie} from "@/utils/cookie";
export default {
  name: 'MyPage',
  data() {
    return {
      loading: false,
    };
  },
  watch: {
  },
  created() {
    if(this.walletLogin){
      axios.get(config.apiHost+'/account', {
        headers: {
          'Content-Type': 'application/json',
          'loginsign':getCookie("signature"),
          'Loginmsg':getCookie("login_msg"),
          'invitefrom':getCookie("invite_from")
        }
      }).then(response => {
        var orderdata = response.data
        if(orderdata.login){
          if(orderdata.ok){
            this.$store.commit('setAccountInfo', orderdata);
          }
        }else{
          this.$store.commit('setLoginOut')
          this.$router.push({ name: "MyPage", params: {} });
        }
      }).catch(error => {
        console.error(error);
      });
    }
  },
  mounted() {
  },
  computed: {
    ...mapState({
      walletConnected: state => state.walletConnected,
      walletLogin: state => state.walletLogin,
      accountAddress: state => state.accountAddress,
      walletConnectModel: state => state.walletConnectModel,
      team_arp: state => state.team_arp,
      balance: state => state.balance,
      level: state => state.level,
      account_info: state => state.account_info,
      showWalletDialog: state => state.showWalletDialog,
    }),
  },
  methods: {
    async toWalletLogin(){
      try {
        this.$store.commit('setShowWalletMsg',this.$t('go_wallet_to_do'));
        await this.$store.dispatch('walletLogin');
        this.$store.commit('setShowWalletMsg',"");
      } catch (error) {
        console.error('Failed to disconnect:', error);
      }
    }
  },
};
</script>

<style scoped>


.account-info {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.account-id {
  font-size: 1rem;
  margin-bottom: 10px;
  width:100%
}
/* 媒体查询：当屏幕宽度小于等于 800px 时应用 */
@media (max-width: 600px) {
  .account-id {
    width: 300px; /* 手机端宽度为屏幕宽度 */
  }
}
.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.info-item .label {
  font-size: 1rem;
  color: #bbb;
}

.info-item .value {
  font-size: 1rem;
  margin-top: 5px;
}

.action-list {
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}
.action-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #444;
  text-decoration: none;
  color:#ffffff;
}

.action-item:last-child {
  border-bottom: none;
}

.action-item .icon {
  font-size: 1rem;
}

.action-item .label {
  flex: 1;
  margin-left: 10px;
}

.action-item .chevron {
  font-size: 1rem;
  color: #bbb;
}




.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.logo {
  width: 200px;
  height: 200px;
}


.tologin-button {
  width: 100%;
  display: block;
  padding: 10px;
  background-color: #ff00ff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
}

.tologin-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}


.login-button {
  padding: 10px 40px;
  background-color: #ff00ff;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
}

.login-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}


.login-button:hover {
  background-color: #e600e6;
}

.team_info{
  padding-right: 2rem;
}


</style>
