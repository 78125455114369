export default {
    appname: 'Financial Management',
    login: 'Login',
    go_wallet_to_do:"Please operate in the wallet client!",
    investment_is_too_small:"The investment amount is lower than the minimum investment amount !",
    no_balance:"The balance is not enough, please recharge first!",
    walletConnected:"Connect wallet",
    openWallet: "Please open the wallet client",
    app:{
        product:"product",
        order:"order",
        my:"my",
    },
    productDetail:{
        arp:"ARP",
        minimum:"Minimum",
        holdingPeriod:"Holding period",
        day:"Day",
        basic_interest:"Basic interest",
        team_interest:"Team interest",
        total_interest:"Total interest",
        expected_returns:"Expected returns",
        principal:"Principal",
        purchase_confirmation:"Purchase confirmation",
        condescribe1:"Are you sure buy",
        condescribe2:"product",
        condescribe3:"locking",
        condescribe4:"days",
        condescribe5:"arp",
        condescribe6:"expected returns",
        condescribe7:"",
    },
    orderPage:{
        noOrders:"No orders yet",
        lockedPos:"Locked position interest",
        waitRed:"Waiting for redemption",
        redComple:"Redemption completed",
        unexpected:"Unexpected termination",
        arp:"ARP",
        end_time:"Expiration",

    },
    orderDetail:{
        purchTime:"Purchase time",
        basic_interest:"Basic interest",
        team_interest:"Team interest",
        total_profile:"Total profit",
        total_interest:"Total interest",
        buy_amount:"Investment amount",
        end_time:"Expiration time",
        time:"time",
    },
    my:{
        availableFunds:"Available Funds",
        team_interest:"Team interest",
        withdrawals:"Withdrawals",
        charge:"Recharge",
        bill:"Bill",
        team:"Team",
        announcement:"Announcement",
    },
    withdraw:{
        minimumWithdrawal:"Minimum withdrawal",
        fees:"Fees",
        every:"time",
        availableBalance:"Available balance",
        withdrawalAmount:"Withdrawal amount",
        time:"time",
        date:"date",
        amount:"Amount",
        state:"State",
        finish:"Finish",
        pendding:"Pendding",
        fail:"Fail",
        minwithdrawal:"Wrong withdrawal amount, minimum withdrawal ",

    },
    depositPage:{
        mincharge:"Minimum deposit",
        chargeamount:"Recharge amount",
        rechargeDes:"The platform recharge address is a public payment account. Please recharge through your wallet and do not recharge through the exchange withdrawal method.",
        rechargeDes2:"Please keep your private key safe. Disclosing your private key means you will lose control of your platform assets.",
        charge:"Recharge",
        time:"time",
        date:"date",
        amount:"Amount",
        state:"State",
        rechargeOk:"Recharge successful, waiting for the account to arrive!",
        rechargeFail:"Recharge failed",
        someError:"some error",
        rechargeAmountError:"Wrong amount of recharge",
        minRecharge:"Minimum deposit 10 USDT",
        balanceNo:"Insufficient balance",
        finish:"Finish",
        pendding:"Pendding",
        fail:"Fail",

    },
    historyPage:{
        noHistory:"No history page",
        finish:"Finish",
        pendding:"Pendding",
        fail:"Fail",
        buy:"Buy",
        sell:"Sell",
        recharge:"Recharge",
        withdrawal:"Withdrawal",
        award:"Award",
        other:"Other",
        fee:"Fee",
    },
    teamPage:{
        desc:"When your friends log in to the platform through your invitation link to participate in the investment, both parties will receive a 10USDT invitation reward. After inviting a specified number of valid users, you will receive a specified team interest, which will take effect immediately.",
        invitValid:"Valid number of invitations",
        team_interest:"Team interest",
        copy_url:"Copy invitation link",
        sleep:"Sleep",
        activation:"Activation",
        copyOk:"Invite link copied to clipboard！",
        copyFail:"Failed to copy the invitation link, please copy it manually：",
    },
    postPage:{
        email:"Contact us by email",
        tg:"Telegram Contact Us"
    },
    postDetail:{
        sincerely:"Sincerely",
        tg:"Telegram official customer service"
    },
    homePage:{
        arp:"ARP",
        minimum:"Minimum",
        holdingPeriod:"Holding",
        day:"Day",
    },
    button: {
        buy: 'Buy',
        confirmation: 'Confirmation',
        cancel: 'Cancel',
        charge: 'Recharge',
        redemption: 'Redemption',
        withdrawal:"Withdrawal",
    }
};