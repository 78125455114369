<template>
  <main>
    <div class="content-section">
      <p>&nbsp;&nbsp;&nbsp;&nbsp;{{$t('teamPage.desc')}}</p>

      <div>
        <table class="table-container">
          <thead>
          <tr>
            <th>{{$t('teamPage.invitValid')}}</th>
            <th>{{$t('teamPage.team_interest')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in team_arp_map"  :key = "index" >
            <td style="text-align: center">{{index}}</td>
            <td style="text-align: center">{{item}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="link-section">
      <div style="padding: 1rem"><img style="width: 40%" :src="image_url"/></div>
      <div style="padding: 1rem"><button class="copy-button" @click="copyLinkToClipboard()">{{$t('teamPage.copy_url')}}</button></div>
    </div>


    <div v-if="data.length>0" class="account-list">
      <div  v-for="(item, index) in data"  :key = "index"  class="account-item">
        <div class="account-id">{{item["address"]}}</div>
        <div class="account-status">{{status[item["status"]]}}</div>
      </div>
    </div>
  </main>

  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>

</template>

<script>
import axios from "axios";
import config from "@/config";
import {getCookie} from "@/utils/cookie";
import {mapState} from "vuex";
export default {
  name: 'HistoryPage',
  data() {
    return {
      amount:0,
      showerror:false,
      errorMsg:"",
      loading:false,
      data:[],
      link:config.apiHost+"/invite/",
      status:{
        0:this.$t('teamPage.sleep'),
        1:this.$t('teamPage.activation'),
      },
      team_arp_map:{},
      image_url:""
    }
  },
  mounted(){
    const QRCode = require('qrcode-generator');
    const qr = QRCode(0, 'L');
    qr.addData(this.link+this.accountAddress);
    qr.make();
    this.image_url = qr.createDataURL(20);
  },
  created() {
    this.fetchHistory()
  },
  watch: {
    accountAddress(newValue, oldValue) {
      console.log(oldValue)
      if(newValue!=""){
        const QRCode = require('qrcode-generator');
        const qr = QRCode(0, 'L');
        qr.addData(this.link+this.accountAddress);
        qr.make();
        this.image_url = qr.createDataURL(20);
      }
    }
  },
  computed: {
    ...mapState({
      walletConnected: state => state.walletConnected,
      walletLogin: state => state.walletLogin,
      accountAddress: state => state.accountAddress,
      walletConnectModel: state => state.walletConnectModel,
      team_arp: state => state.team_arp,
      balance: state => state.balance,
      level: state => state.level,
      account_info: state => state.account_info,
    }),
  },
  methods: {
    copyLinkToClipboard() {
      // 创建一个文本区域元素，用于临时存储链接
      var tempInput = document.createElement("input");
      tempInput.style.position = 'fixed';
      tempInput.style.left = '0';
      tempInput.style.top = '0';
      tempInput.style.opacity = '0';
      document.body.appendChild(tempInput);

      // 将邀请链接放入文本区域
      tempInput.value = this.link+this.accountAddress;

      // 选中文本区域的内容
      tempInput.select();

      // 尝试复制到剪贴板
      try {
        document.execCommand('copy');
        alert(this.$t('teamPage.copyOk'));
      } catch (err) {
        alert(this.$t('teamPage.copyFail') +  this.link+this.accountAddress);
      }

      // 清除临时文本区域
      document.body.removeChild(tempInput);
    },
    closeError(){
      this.showerror = false
    },
    openError(msg){
      this.errorMsg = msg
      this.showerror = true
    },
    async fetchHistory(){
      this.loading = true
      axios.get(config.apiHost+'/team_list', {
        headers: {
          'Content-Type': 'application/json',
          'loginsign':getCookie("signature"),
          'Loginmsg':getCookie("login_msg"),
        }
      }).then(response => {
        // 处理响应数据
        var data = response.data
        if(data.login){
          if(data.ok){
            this.data = data.teamlist;
            this.team_arp_map = data.team_arp_map
          }else{
            this.$store.commit('setShowWalletMsg',data.msg);
          }
        }else{
          this.$store.commit('setLoginOut')
          this.$router.push({ name: "MyPage", params: {} });
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        // 处理错误
        console.error(error);
      });
    }
  }
};
</script>

<style scoped>

.account-list {
  margin-top: 1rem;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 5rem;
}

.account-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #444;
}

.account-item:last-child {
  border-bottom: none;
}

.account-id {
  font-size: 1rem;
}

.account-status {
  font-size: 1rem;
  color: #bbb;
}


.content-section {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.link-section {
  text-align: center;
}

.link-section a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  display: block;
  margin-bottom: 20px;
}

.copy-button {
  padding: 10px 20px;
  background-color: #ff00ff;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
}
.copy-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}


.table-container {
  background-color: #333;
  width: 80%; /* 或者任何固定的宽度 */
  margin-left: auto;
  margin-right: auto;
}

</style>
