<template>
  <main>
    <div class="withdraw-section">
      <div class="withdraw-amount">
        <label for="amount">{{ $t('withdraw.minimumWithdrawal') }}</label>
        <div class="input-group">
          <span>{{min_amount}} USDT</span>
        </div>
      </div>
      <div class="withdraw-amount">
        <label for="amount">{{ $t('withdraw.fees') }}</label>
        <div class="input-group">
          <span>{{withdrawals_fee}} USDT/{{ $t('withdraw.every') }}</span>
        </div>
      </div>
      <div class="withdraw-amount">
        <label for="amount">{{ $t('withdraw.availableBalance') }}</label>
        <div class="input-group">
          <input type="number" readonly id="balance" v-model="balance">
          <span>USDT</span>
        </div>
      </div>
      <div class="withdraw-amount">
        <label for="amount">{{ $t('withdraw.withdrawalAmount') }}</label>
        <div class="input-group">
          <input type="number" id="amount" v-model="amount">
          <span>USDT</span>
        </div>
      </div>
      <button class="withdraw-button" @click="toWithdrawals()">{{ $t('button.withdrawal') }}</button>
    </div>
    <div v-if="data.length>0" class="table-container">
      <table>
        <thead>
        <tr>
          <th>{{ $t('withdraw.date') }}</th>
          <th>{{ $t('withdraw.time') }}</th>
          <th>{{ $t('withdraw.amount') }}</th>
          <th>{{ $t('withdraw.state') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>{{item["create_at_date"]}}</td>
          <td>{{item["create_at_time"]}}</td>
          <td>{{item["amount"].toFixed(2)}}</td>
          <td>{{withdrawalsStatus[item["status"]]}}</td>
        </tr>

        </tbody>
      </table>
    </div>
  </main>

  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import config from "@/config";
import axios from "axios";
import {getCookie} from "@/utils/cookie";
import {Web3} from "web3";

export default {
  name: 'WithdrawalsPage',
  data() {
    return {
      min_amount:100,
      withdrawals_fee:5,
      amount:0,
      loading:false,
      data:[],
      withdrawalsStatus:{
        0:this.$t('finish'),
        1:this.$t('pendding'),
        2:this.$t('fail'),
      }
    }
  },
  created() {
    this.fetchHistory()
  },
  computed: {
    ...mapState({
      showWalletDialog: state => state.showWalletDialog,
      walletConnected: state => state.walletConnected,
      walletLogin: state => state.walletLogin,
      accountAddress: state => state.accountAddress,
      walletConnectModel: state => state.walletConnectModel,
      team_arp: state => state.team_arp,
      balance: state => state.balance,
      level: state => state.level,
      account_info: state => state.account_info,
    }),
  },
  methods: {
    async fetchHistory(){
      this.loading = true
      axios.get(config.apiHost+'/withdrawals_history', {
        headers: {
          'Content-Type': 'application/json',
          'loginsign':getCookie("signature"),
          'Loginmsg':getCookie("login_msg"),
        }
      }).then(response => {
        // 处理响应数据
        var data = response.data
        if(data.login){
          if(data.ok){
            this.data = data.data;
          }else{
            this.$store.commit('setShowWalletMsg',data.msg);
          }
        }else{
          this.$store.commit('setLoginOut')
          this.$router.push({ name: "MyPage", params: {} });
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        // 处理错误
        console.error(error);
      });
    },



    async tobuytypesign(serverD){
      try {
        const provider = await this.walletConnectModel.getWalletProvider();
        const web3 = new Web3(provider);
        this.$store.commit('setShowWalletMsg',this.$t('go_wallet_to_do'));
        const signature = await web3.eth.signTypedData(this.walletConnectModel.getAddress(), serverD['extra_data']);
        const yewu_data = {
          withdrawals_sign: signature,
          amount:this.amount,
        }
        this.loading = true
        var data = {
          "method":"withdrawals",
          "type":"typesign",
          "type_id":serverD['type_id'],
          "data":yewu_data,
          "extra_data":serverD['extra_data'],
          "signature":signature,
          "sign_msg":serverD['extra_data'],
        }
        if (this.walletConnectModel.getChainId() == 56){
          data["network"] = "bsc"
        }
        await axios.post(config.apiHost+'/sign_method', data,{
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        }).then(response => {
          this.loading = false
          var data = response.data
          if(data.login){
            if(data.ok){
              this.$store.commit('setBalance', this.balance-this.amount);
              this.fetchHistory()
            }else{
              this.$store.commit('setShowWalletMsg',data.msg);
              this.$store.commit('setShowWalletMsg',"");
            }
          }else{
            this.$store.commit('setLoginOut')
            this.$router.push({ name: "MyPage", params: {} });
          }

        }).catch(error => {
          console.error(error);
        });
      } catch (error) {
        this.loading = false
        this.showerror = false
        this.showDialog = false
        console.log(error)
      } finally {
        console.log("ok")
      }
      this.loading = false
    },
    async tobuysign(serverD){
      try {
        const provider = await this.walletConnectModel.getWalletProvider();
        const web3 = new Web3(provider);
        const hexMessage = web3.utils.utf8ToHex(serverD["sign_msg"]);
        this.$store.commit('setShowWalletMsg',this.$t('go_wallet_to_do'));
        const signature = await web3.eth.personal.sign(hexMessage, this.walletConnectModel.getAddress(), "");
        const yewu_data = {
          withdrawals_sign: signature,
          amount:this.amount,
        }
        this.loading = true
        var data = {
          "method":"withdrawals",
          "type":"sign",
          "data":yewu_data,
          "extra_data":serverD['extra_data'],
          "signature":signature,
          "sign_msg":serverD['sign_msg'],
        }
        if (this.walletConnectModel.getChainId() == 56){
          data["network"] = "bsc"
        }
        await axios.post(config.apiHost+'/sign_method', data,{
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        }).then(response => {
          var data = response.data
          if(data.login){
            if(data.ok){
              this.$store.commit('setBalance', this.balance-this.amount);
              this.fetchHistory()
              this.$store.commit('setShowWalletMsg',"");
            }else{
              this.$store.commit('setShowWalletMsg',data.msg);
            }
          }else{
            this.$store.commit('setLoginOut')
            this.$router.push({ name: "MyPage", params: {} });
          }
        }).catch(error => {
          console.error(error);
        });
      } catch (error) {
        this.loading = false
        this.showerror = false
        this.showDialog = false
        console.log(error)
      } finally {
        console.error(2);
      }
      this.loading = false
    },
    async handleResponse(response) {
      var serverD = response.data
      if(!serverD.login){
        this.$store.commit('setLoginOut')
        this.$router.push({ name: "MyPage", params: {} });
        return
      }
      if(serverD["type"] == "sign"){
        await this.tobuysign(serverD)
      }
      if(serverD["type"] == "typesign"){
        await this.tobuytypesign(serverD)
      }
    },
   async toWithdrawals() {
      if(this.amount<this.min_amount){
        this.$store.commit('setShowWalletMsg',this.$t('withdraw.minwithdrawal')+this.min_amount+ 'USDT');
        return
      }
     this.amount = parseFloat(this.amount.toFixed(2))
      if((this.amount+this.withdrawals_fee) > this.balance){
        this.$store.commit('setShowWalletMsg',"余额不足!");
      }else{
        var data = {
          "method":"withdrawals",
          "data":{
            "account":this.walletConnectModel.getAddress()
          }
        }
        if (this.walletConnectModel.getChainId() == 56){
          data["network"] = "bsc"
        }
        const response = await axios.post(config.apiHost+'/getsign', data, {
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        })
        await this.handleResponse(response)
      }
    },
  }
};
</script>

<style scoped>

.withdraw-section {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* 媒体查询：当屏幕宽度小于等于 800px 时应用 */
@media (max-width: 600px) {
  .withdraw-section  {
    width: 300px; /* 手机端宽度为屏幕宽度 */
  }
}

.withdraw-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.withdraw-amount label {
  font-size: 1rem;
}

.input-group {
  display: flex;
  align-items: center;
  background-color: #4d4d4d;
  padding: 5px 10px;
  border-radius: 5px;
}

.input-group input {
  border: none;
  background-color: transparent;
  color: white;
  text-align: right;
  font-size: 1rem;
  width:100px;

}

.input-group span {
  margin-left: 10px;
  font-size: 1rem;
}

.withdraw-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ff00ff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
}
.withdraw-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}

.table-container {
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #444;
}

th, td {
  padding: 10px;
  text-align: center;
}

.info-item span {
  margin-top: 5px;
  font-size: 1rem;
  color: #bbb;
}

.investment input {
  width: 80px;
  border: none;
  background-color: transparent;
  color: white;
  text-align: right;
  font-size: 1rem;
}

.dialog-header span {
  font-size: 18px;
  font-weight: bold;
}


</style>
