import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store'; // 引入你创建的store
import { setCookie,getCookie } from './utils/cookie';

const app = createApp(App);
app.config.globalProperties.setCookie = setCookie;
app.config.globalProperties.getCookie = getCookie;
app.use(router);
app.use(store); // 将store挂载到Vue实例上
app.use(i18n);
app.mount('#app');