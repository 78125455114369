<template>

  <main>
    <div class="investment-list">
      <div v-for="(item, index) in data" :key="index" @click=ToView(item) class="investment-item">
        <div class="investment-title">{{item.name}}</div>
        <div class="investment-detail">
          <div class="info">{{$t('homePage.arp')}} <span>{{item.arp}}%</span></div>
          <div class="info">{{$t('homePage.minimum')}} <span>💲{{item.start_funds}}</span></div>
          <div class="info">{{$t('homePage.holdingPeriod')}} <span>{{item.cycle}} {{$t('homePage.day')}}</span></div>
        </div>
      </div>
    </div>
  </main>

  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>
</template>

<script>
import axios from "axios";
import config from '../config';
import {getCookie} from "@/utils/cookie";
export default {
  name: 'HomePage',
  data() {
    return {
      loading: true,
      data: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    ToView(item){
      this.$router.push({ name: "productDetail", params: { id: item.id,name: item.name,arp: item.arp,start_funds: item.start_funds,cycle: item.cycle,describe: item.describe,team_arp: item.team_arp} });
    },
    async fetchData() {
      try {
        const response = await axios.get(config.apiHost+'/product_list',{
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        });
        this.data = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

.investment-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 5rem;
}

.investment-item {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  text-decoration: none;
  color: white;
}

.investment-title {
  font-size: 1rem;
  margin-bottom: 10px;
}

.investment-detail {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.investment-detail .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.investment-detail .info span {
  margin-top: 5px;
  font-size: 1rem;
  color: #bbb;
}





</style>
