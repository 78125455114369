<template>
  <main>
    <div class="withdraw-section">
      <div class="withdraw-amount">
        <label for="amount">{{$t('depositPage.mincharge')}}</label>
        <div class="input-group">
          <span>{{min_deposit}} USDT</span>
        </div>
      </div>
      <div class="withdraw-amount">
        <label for="amount">{{$t('depositPage.chargeamount')}}</label>
        <div class="input-group">
          <input type="number" id="amount"  v-model="amount">
          <span>USDT</span>
        </div>
      </div>
      <p style="font-size: 0.8rem">&nbsp;&nbsp;&nbsp;&nbsp;⚠️⚠️⚠️{{$t('depositPage.rechargeDes')}}</p>
      <p style="font-size: 0.8rem">&nbsp;&nbsp;&nbsp;&nbsp;⚠️⚠️⚠️{{$t('depositPage.rechargeDes2')}}</p>
      <button class="withdraw-button" @click="toCharge()">{{$t('button.charge')}}</button>
    </div>
    <div v-if="data.length>0" class="table-container">
      <table>
        <thead>
        <tr>
          <th>{{$t('depositPage.date')}}</th>
          <th>{{$t('depositPage.time')}}</th>
          <th>{{$t('depositPage.amount')}}</th>
          <th>{{$t('depositPage.state')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>{{item["create_at_date"]}}</td>
          <td>{{item["create_at_time"]}}</td>
          <td>{{item["amount"].toFixed(2)}}</td>
          <td>{{withdrawalsStatus[item["status"]]}}</td>
        </tr>

        </tbody>
      </table>
    </div>
  </main>
  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import axios from "axios";
import config from "@/config";
import {getCookie} from "@/utils/cookie";
import {Web3} from "web3";
const contractABI = [{"constant":true,"inputs":[{"name":"who","type":"address"}],"name":"balanceOf","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":false,"inputs":[{"name":"_to","type":"address"},{"name":"_value","type":"uint256"}],"name":"transfer","outputs":[{"name":"","type":"bool"}],"type":"function"}];
export default {
  name: 'DepositPage',
  data() {
    return {
      min_deposit:10,
      amount:0,
      showerror:false,
      errorMsg:"",
      loading:false,
      data:[],
      withdrawalsStatus:{
        0:this.$t('depositPage.finish'),
        1:this.$t('depositPage.pendding'),
        2:this.$t('depositPage.fail'),
      },
      chainId:1,
      contractAddress:"0xdAC17F958D2ee523a2206206994597C13D831ec7"
    }
  },
  created() {
    var chainId = this.walletConnectModel.getChainId()
    this.chainId = chainId
    if(chainId == 56){
        this.contractAddress = "0x55d398326f99059fF775485246999027B3197955"
    }
    this.fetchHistory()
  },
  computed: {
    ...mapState({
      walletConnected: state => state.walletConnected,
      walletLogin: state => state.walletLogin,
      accountAddress: state => state.accountAddress,
      walletConnectModel: state => state.walletConnectModel,
      team_arp: state => state.team_arp,
      balance: state => state.balance,
      level: state => state.level,
      account_info: state => state.account_info,
      showWalletDialog: state => state.showWalletDialog,
    }),
  },
  methods: {
    closeError(){
      this.showerror = false
    },
    openError(msg){
      this.errorMsg = msg
      this.showerror = true
    },
    async fetchHistory(){
      this.loading = true
      axios.get(config.apiHost+'/charge_history', {
        headers: {
          'Content-Type': 'application/json',
          'loginsign':getCookie("signature"),
          'Loginmsg':getCookie("login_msg"),
        }
      }).then(response => {
        // 处理响应数据
        var data = response.data
        if(data.login){
          if(data.ok){
            this.data = data.data;
          }else{
            this.$store.commit('setShowWalletMsg',data.msg);
          }
        }else{
          this.$store.commit('setLoginOut')
          this.$router.push({ name: "MyPage", params: {} });
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        // 处理错误
        console.error(error);
      });
    },
    async  transfer(contract,receiverAddress,amountToTransfer,account,gasPrice,gaslimit){
      this.$store.commit('setShowWalletMsg',this.$t('go_wallet_to_do'));
      await contract.methods.transfer(receiverAddress, amountToTransfer).send({ from:account,
        gasPrice: gasPrice,gaslimit:gaslimit})
          .then((receipt) => {
            console.log('Transaction receipt:', receipt);
            this.$store.commit('setShowWalletMsg', this.$t('depositPage.rechargeOk'));
          })
          .catch((error) => {
            console.error('Error transferring ERC20 token:', error);
            this.$store.commit('setShowWalletMsg',this.$t('depositPage.rechargeFail'));
          });
    },
    async toWalletToCharge(web3,modal){
      try {
        const gasPrice = await web3.eth.getGasPrice();
        const contract = new web3.eth.Contract(contractABI, this.contractAddress)
        const receiverAddress = config.chargeAddress;
        var decimal = 6
        if(this.chainId == 56){
          decimal = 18
        }
        const amountToTransfer = this.amount*10**decimal;
        const txObject = {
          to:       this.contractAddress,
          data: contract.methods.transfer(receiverAddress,amountToTransfer).encodeABI(),
          from: modal.getAddress()
        }
        web3.eth.estimateGas(txObject).then((gaslimit)=>{
          this.transfer(contract,receiverAddress,amountToTransfer,modal.getAddress(),gasPrice,gaslimit)
        });
      } catch (error) {
        console.log(error)
        this.$store.commit('setShowWalletMsg',this.$t('depositPage.someError') );
      } finally {
        //console.error(2);
      }
    },
    async toCharge() {
      if(this.amount <=0){
        this.$store.commit('setShowWalletMsg',this.$t('depositPage.rechargeAmountError'));
        return
      }
      if (this.amount < this.min_deposit){
        this.$store.commit('setShowWalletMsg',this.$t('depositPage.minRecharge') );
        return
      }
      this.loading = true
      const provider = await this.walletConnectModel.getWalletProvider();
      const web3 = new Web3(provider);
      var MyContract = new web3.eth.Contract(contractABI, this.contractAddress);
      MyContract.methods.balanceOf(this.accountAddress).call()
          .then((balance)=>{
            this.amount = parseFloat(this.amount.toFixed(2))
            var decimal = 6
            if(this.chainId == 56){
              decimal = 18
            }
            if(balance>this.amount*10**decimal){
              this.toWalletToCharge(web3,this.walletConnectModel)
            }else{
              this.$store.commit('setShowWalletMsg',this.$t('depositPage.balanceNo') );
            }
            this.loading = false
          });
    },
  }
};
</script>

<style scoped>

.withdraw-section {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
/* 媒体查询：当屏幕宽度小于等于 800px 时应用 */
@media (max-width: 600px) {
  .withdraw-section  {
    width: 300px; /* 手机端宽度为屏幕宽度 */
  }
}


.withdraw-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.withdraw-amount label {
  font-size: 1rem;
}

.input-group {
  display: flex;
  align-items: center;
  background-color: #4d4d4d;
  padding: 5px 10px;
  border-radius: 5px;
}

.input-group input {
  border: none;
  background-color: transparent;
  color: white;
  text-align: right;
  font-size: 1rem;
  width:100px;
}

.input-group span {
  margin-left: 10px;
  font-size: 1rem;
}

.withdraw-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ff00ff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
}

.withdraw-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}

.table-container {
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #444;
}

th, td {
  padding: 10px;
  text-align: center;
}


</style>
