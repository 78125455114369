export default {
    appname: '理财宝',
    login: '登录',
    go_wallet_to_do:"请在钱包客户端操作!",
    investment_is_too_small:"投资金额低于起投资金 !",
    no_balance:"余额不够,请先充值!",
    walletConnected:"连接钱包",
    openWallet: "请打开钱包客户端",
    app:{
        product:"产品",
        order:"订单",
        my:"我的",
    },
    productDetail:{
        arp:"年化",
        minimum:"起投资金",
        holdingPeriod:"投资周期",
        day:"天",
        basic_interest:"基本利息",
        team_interest:"团队利息",
        total_interest:"总共利息",
        expected_returns:"预期收益",
        principal:"本金",
        purchase_confirmation:"购买确认",
        condescribe1:"您确认购买",
        condescribe2:"产品",
        condescribe3:"锁仓",
        condescribe4:"天",
        condescribe5:"年化利息",
        condescribe6:"预期收益",
        condescribe7:"吗",

    },
    orderPage:{
        noOrders:"暂无任何订单",
        lockedPos:"锁仓计息",
        waitRed:"等待赎回",
        redComple:"赎回完成",
        unexpected:"意外中止",
        arp:"年化",
        end_time:"到期时间",
    },
    orderDetail:{
        purchTime:"购买时间",
        basic_interest:"基本利息",
        team_interest:"团队利息",
        total_profile:"总共盈利",
        total_interest:"总共利息",
        buy_amount:"投资金额",
        end_time:"到期时间",
        time:"时间",
    },
    my:{
        availableFunds:"可用资金",
        team_interest:"团队利息",
        withdrawals:"提款",
        charge:"充值",
        bill:"账单",
        team:"团队",
        announcement:"公告"

    },
    withdraw:{
        minimumWithdrawal:"最低提现",
        fees:"手续费用",
        every:"次",
        availableBalance:"可用余额",
        withdrawalAmount:"提现金额",
        time:"时间",
        date:"日期",
        amount:"金额",
        state:"状态",
        finish:"完成",
        pendding:"等待",
        fail:"失败",
        minwithdrawal:"提款金额错误,最低提款",

    },
    depositPage:{
        mincharge:"最低充值",
        chargeamount:"充值金额",
        rechargeDes:"平台充值地址是对公收款账户，请通过钱包充值，切勿通过交易所提款方式充值。",
        rechargeDes2:"请保护私钥安全，泄露私钥意味着您丢失平台资产的控制权。",
        charge:"充值",
        time:"时间",
        date:"日期",
        amount:"金额",
        state:"状态",
        rechargeOk:"充值成功，等待到账！",
        rechargeFail:"充值失败",
        someError:"出错啦",
        rechargeAmountError:"充值金额错误",
        minRecharge:"最低充值 10 USDT",
        balanceNo:"余额不足",
        finish:"完成",
        pendding:"等待",
        fail:"失败",

    },
    historyPage:{
        noHistory:"暂无任何账单",
        finish:"完成",
        pendding:"等待",
        fail:"失败",
        buy:"买入",
        sell:"卖出",
        recharge:"充值",
        withdrawal:"提款",
        award:"奖励",
        other:"其他",
        fee:"手续费",
    },
    teamPage:{
        desc:"朋友通过您邀请链接登入平台参与投资，双方都将获得10usdt邀请奖励，累计邀请指定数量的有效用户，你将获得指定的团队利息，团队利息及时生效。",
        invitValid:"邀请有效人数",
        team_interest:"团队利息",
        copy_url:"复制邀请链接",
        sleep:"休眠",
        activation:"激活",
        copyOk:"邀请链接已复制到剪贴板！",
        copyFail:"复制邀请链接失败，请手动复制：",

    },
    postPage:{
        email:"邮件联系我们",
        tg:"TG联系我们"
    },
    postDetail:{
        sincerely:"团队敬上",
        tg:"TG官方客服"
    },
    homePage:{
        arp:"年化",
        minimum:"起投资金",
        holdingPeriod:"投资周期",
        day:"天",
    },
    button: {
        buy: '购买',
        confirmation: '确认',
        cancel: '取消',
        charge: '充值',
        redemption: '赎回',
        withdrawal:"提款",
    }
};